<template>
  <v-dialog v-model="modalData.dialog" max-width="750px" persistent>
    <v-card class="mx-auto" outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-btn
              depressed
              color="primary"
              class="ma-2"
              v-if="
                formModel.id &&
                !cloneFormTemplate &&
                (permissionCan('create') || permissionCan('update'))
              "
              @click="handleCloneFormTemplate"
            >
              {{ $t("FORM_INPUT_NAMES.clone") }}
            </v-btn>

            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.pages"
                v-model="formModel.status"
                :items="statuses.pages.menus"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].label"
                :label="$t('FORMS.label')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.label']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.label'] =
                    ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-4">
              <treeselect
                v-model="formModel.menu_id"
                :clearable="clearable"
                :searchable="searchable"
                :options="computedParentMenus"
                :placeholder="$t('FORMS.parent')"
                :id="dynamicID"
                :noChildrenText="false"
              >
              </treeselect>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                v-model="formModel.fictional"
                label="Fictional"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].url"
                label="URL"
                :rules="urlRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.url']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.url'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <!-- <CustomFieldComponent
                v-if="formModel && formModel.custom_fields"
                customFieldName="icon"
                :value="formModel.custom_fields.icon || ''"
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.select_icon',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent> -->
              <v-text-field
                v-if="formModel.icon || 1 == 1"
                v-model="formModel.icon"
                :label="$t('FORM_INPUT_NAMES.icon')"
                :error-messages="messages['icon']"
                @keyup="messages['icon'] = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="formModel.target"
                :items="targets"
                :label="$t('FORMS.target')"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"> </v-col>
          </v-row>
          <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./Menus";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

export const INITIAL_CUSTOM_FIELDS = {
  icon: "",
};

const TRANSLATED_ATRIBUTES = ["label", "url", "slug"];

export const initialFormData = {
  id: null,
  translations: [],
  target: "_self",
  label: "",
  icon: "",
  slug: "",
  url: "",
  status: 1,
  fictional: 0,
  menu_id: null,
  custom_fields: INITIAL_CUSTOM_FIELDS,
};

export default {
  name: "MenuForm",
  props: ["modalData", "permissions", "statuses"],
  components: {
    Treeselect,
    // , CustomFieldComponent
  },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      //parentMenus: [],
      targets: [
        { key: "_self", value: "Self" },
        { key: "_blank", value: "Blank" },
      ],
      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      urlRules: [(value) => !!value || "This field is required"],
      cloneFormTemplate: false,
    };
  },
  computed: {
    ...mapGetters(["menuFlatCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      if (this.cloneFormTemplate)
        return this.$t("FORMS.menu") + " " + this.$t("FORM_INPUT_NAMES.clone");
      return this.formModel.id
        ? this.$t("FORMS.edit_menu")
        : this.$t("FORMS.new") + " " + this.$t("FORMS.menu");
    },
    computedParentMenus() {
      let tree = this.createDataForTreeselect(
        this.menuFlatCollection,
        null,
        this.formModel.id
      );
      return tree;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        if (value.editedId) {
          //this.getParentMenus(value.editedId);
          this.loader = true;
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              this.loader = false;
            })
            .catch((error) => {
              console.log("Error!: ", error);
              this.loader = false;
            });
        } else {
          //this.getParentMenus();
          this.formModel = Object.assign({}, initialFormData);
          if (value.menu_id) {
            this.formModel.menu_id = value.menu_id;
          }
          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchFlatMenu"]),
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.formModel = Object.assign({}, initialFormData);
      this.$refs.form.resetValidation();
      this.cloneFormTemplate = false;
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      this.$refs.form.validate();
      if (typeof model.menu_id == "undefined") {
        model.menu_id = null;
      }

      if (this.formValid) {
        this.resetErrorMessages();
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            let isTranslated = false;
            TRANSLATED_ATRIBUTES.forEach((attibute) => {
              if (
                model.translations[langCode] &&
                model.translations[langCode][attibute] &&
                model.translations[langCode][attibute] != ""
              ) {
                isTranslated = true;
              }
            });
            if (isTranslated) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
              this.loader = false;
              this.cloneFormTemplate = false;
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
              this.loader = false;
              this.cloneFormTemplate = false;
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.loader = false;
            });
        }
      }
    },

    setTranslatedAttributes() {
      if (!this.formModel.id) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
    /*getParentMenus(menu_id = null) {
        this.parentMenus = [];
        let MENU_FOR_TREESELECT_ENDPOINT = ENDPOINT.substring(0, ENDPOINT.length - 1) + "-flat";
        this.loader = true;
        ApiService.get(MENU_FOR_TREESELECT_ENDPOINT)
            .then(({ data }) => {
                this.parentMenus = this.createDataForTreeselect(data,null,menu_id);
                this.loader = false;
            })
            .catch((error) => {
                console.log("Error!: ", error);
                this.loader = false;
            });
    },*/

    resetErrorMessages() {
      let errorData = Object.assign({}, initialFormData);
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "pages.menu." + action);
    },

    createDataForTreeselect(layer, menu_id = null, edited_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.menu_id === menu_id) {
          let isDisabled = false;
          if (edited_id && (edited_id == item.id || edited_id == menu_id)) {
            isDisabled = true;
          }
          vertex.set(item.id, {
            id: item.id,
            label: this.$helpers.getTranslated(item.translations).label,
            isDisabled: isDisabled,
          });
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(
          others,
          vertexId,
          edited_id
        );
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },

    handleCloneFormTemplate() {
      this.cloneFormTemplate = true;
      this.formModel.translations[this.selectedLocale.lang].name =
        this.formModel.translations[this.selectedLocale.lang].name + "_clone";
      this.formModel.id = null;
    },
  },

  async mounted() {
    this.setTranslatedAttributes();
    //this.fetchFlatMenu();
  },
};
</script>
